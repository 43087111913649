<template>
  <div id="login">
    <Component v-for="(component, index) in components" v-bind:key="index" v-bind:is="component.name"/>
  </div>
</template>

<script>
  export default {
    computed: {
      components() {
        return this.$store.getters["system/pages"].find(page => page.name == "Login").children;
      },
    },
  };
</script>